import React, { useState, useEffect } from "react";
import Footer from "../components/Footer/Footer.tsx";
import Map from "../components/Map/Map.tsx";
import NavbarStatic from "../components/Nav/NavbarStatic";
import ReactMarkdown from "react-markdown";
import "../App.css";
import "./Karta.css";

const query = `
{
  menyCollection {
    items {
        title
        text
        extraText
    }
  }
}
`;

const KartaPage = () => {
  const [karta, setKarta] = useState(null);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/zr788oaaef6o/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer Gb6mXKp6IFzto-esCYOYC4sy6Gn3qDMHpIqPcsVl6T0",
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setKarta(
          data.menyCollection.items.filter((item) => item.title === "Hitta hit")
        );
      });
  }, []);

  if (!karta) {
    return "Laddar...";
  }

  const youtube = require("../Images/youtube.png");

  return (
    <div>
      <NavbarStatic />
      <div className="shortHero">
        <h1>Hitta hit</h1>
      </div>
      <div className="page">
        <div className="kartaContainer">
          <div>
            <ReactMarkdown>{karta[0].text}</ReactMarkdown>
          </div>
          <div className="kartaVideo">
            <iframe
              src="https://www.youtube.com/embed/Nr4-qlMW-Z8"
              title="Hitta till bryggorna hos Forsmans på Aspöja"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
          <div className="kartaVideoMobile">
            <a
              href="https://www.youtube.com/embed/Nr4-qlMW-Z8"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={youtube}
                alt="Hitta till bryggorna hos Forsmans på Aspöja"
              />
            </a>
          </div>
        </div>
        <div>
          <ReactMarkdown>{karta[0].extraText}</ReactMarkdown>
        </div>
        <Map />
      </div>
      <Footer />
    </div>
  );
};

export default KartaPage;
