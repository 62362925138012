import React from "react";
import ReactMarkdown from "react-markdown";

import "./About.css";

const Us = ({ omOss }) => {
  return (
    <section className="us">
      <div className="usText">
        <h2>{omOss[0]?.title}</h2>
        <ReactMarkdown>{omOss?.[0]?.text}</ReactMarkdown>
      </div>
      <div
        className="usImage"
        style={{ backgroundImage: `url(${omOss?.[0]?.image?.url})` }}
      ></div>
    </section>
  );
};

export default Us;
