import React from 'react';
import ReactMarkdown from 'react-markdown';
import "./About.css";

const About = ({ introText }) => {
    return (
        <section className="om">
            <ReactMarkdown>
                {introText}
            </ReactMarkdown>
        </section>
    )
}

export default About;