import React from "react";
import NavbarStatic from "../Nav/NavbarStatic";
import { useLocation } from "react-router-dom";

import "./ProductPage.css";

const ProductPage = () => {
  const { state } = useLocation();

  return (
    <div>
      <NavbarStatic />
      <div className="shortHero center">
        <h1>{state?.item?.title}</h1>
        <img src={state?.item?.image?.url} alt={state?.item?.title} />
        <div className="imageWrapper">
          <p>{state?.item?.text}</p>
          <p>
            För att beställa, maila dina addressuppgifter och telefonnummer till
            <a
              className="puffLinkHover"
              style={{ color: "#000000" }}
              href={`mailto:aspoja.helen@telia.com?subject=Beställning av ${state?.item?.title}&body=Hej, jag önskar beställa ${state?.item?.title}%0D%0DMitt namn: %0D%0DMin adress: %0D%0DMitt telefonnummer:`}
            >
              {" "}
              aspoja.helen@telia.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
