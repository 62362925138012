import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import "./Puffs.css";


function LinkRenderer(props: any) {
    return (
    <a className="phone puffPhone" href={props.href}  style={{color: '#ffffff'}} rel="noreferrer">
        {props.children}
    </a>
    );
  }

const Puffs = ({heroPuffs}) => {
    return (
        <div className="puffs">
            <div className="puff">
                <h2>{heroPuffs[0].title}</h2>
                <ReactMarkdown>
                    {heroPuffs[0].text}
                </ReactMarkdown>
            </div>
            <div className="puff">
                <h2>{heroPuffs[1].title}</h2>
                <ReactMarkdown>
                    {heroPuffs[1].text}
                </ReactMarkdown>
                <p>
                <NavLink to="/karta">
                    <span className="puffLinkHover" style={{color: '#ffffff'}}>
                    Hitta till vår brygga genom Arnströmmen</span>
                    <i className="fa fa-arrow-right arrow" aria-hidden="true"></i>
                </NavLink>
                </p>
            </div>
            <div className="puff puffBooking">
                <h2>{heroPuffs[2].title}</h2>
                <ReactMarkdown components={{ a: LinkRenderer}}>
                    {heroPuffs[2].text}
                </ReactMarkdown>
            </div>
        </div>
    )
}

export default Puffs;