import React, {useState, useEffect} from 'react';
import NavbarStatic from '../components/Nav/NavbarStatic';
import Footer from '../components/Footer/Footer.tsx';
import "../App.css";

const query = `
{
  projektsidaCollection(limit:1) {
    items {
      titel
      ingress
      undertitel
      brodtext
      projektLogosCollection {
        items {
          image {
            url
            description
          }
        }
      }
    }
  }
}
`;

const Projekt = () => {
  const [page, setPage] = useState(null);
  
  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/zr788oaaef6o/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer Gb6mXKp6IFzto-esCYOYC4sy6Gn3qDMHpIqPcsVl6T0",
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setPage(data.projektsidaCollection.items[0])
      });
  }, []);

  if (!page) {
    return "Laddar...";
  }

    return (
      <div>
          <NavbarStatic />
            <div className="page">
                <h2>{page?.titel}</h2>
                <p>{page?.ingress}</p>
                <div>
                  <h3 style={{marginBottom: '10px'}}>{page?.undertitel}</h3>
                  <p>{page?.brodtext}</p>
                </div>
                <div style={{marginTop: '25px'}}>
                  {page?.projektLogosCollection?.items?.map((item, index) => 
                    <img src={item.image.url} className="projectLogo" alt={item.image.description} key={index}/>
                  )}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Projekt;