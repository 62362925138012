import React, { useEffect, useState } from 'react';
import useSmoothHorizontalScroll from 'use-smooth-horizontal-scroll';
import './Gallery.css'

const Gallery = ({images}) => {
    const [windowSize, setWindowSize] = useState([window?.innerWidth]);
    const { scrollContainerRef, handleScroll, scrollTo, isAtStart, isAtEnd } = useSmoothHorizontalScroll();
    const mobileScreen = windowSize <= 820;

    useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window?.innerWidth]);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);


    return (
        <>
            <h2>Bilder</h2>
            <div className="galleryWrapper">
                {Boolean(!isAtStart) && <button className="galleryButton" onClick={() => scrollTo(mobileScreen ? -325 : -390)}>
                    <i className="fas fa-angle-left fa-lg"></i>
                </button>}
                <div ref={scrollContainerRef} onScroll={handleScroll} className="gallery">
                    {images.items?.map((image, index) => 
                        <img className="galleryImage" src={image.url} alt={image.title} key={index}/>
                    )}
                </div>
                {Boolean(!isAtEnd) && <button className="galleryButton" onClick={() => scrollTo(mobileScreen ? 325 : 390)}>
                    <i className="fas fa-angle-right fa-lg"></i>
                </button>}
            </div>
        </>
    )
}

export default Gallery;