import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";

import MenuPage from "./Views/Menu.tsx";
import Start from "./Views/Start.tsx";
import OpeningPage from "./Views/Openings.tsx";
import KartaPage from "./Views/Karta.tsx";
import Projekt from "./Views/Projekt.tsx";
import ShopPage from "./Views/Shop.tsx";
import ShopPage2 from "./Views/Shop2.tsx";
import ProductPage from "../src/components/ProductPage/ProductPage.tsx";
import { createBrowserHistory } from "history";
import "./App.css";

function App() {
  const history = createBrowserHistory();

  return (
    <HashRouter basename={process.env.PUBLIC_URL} history={history}>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Start />}></Route>
          <Route exact path="/meny" element={<MenuPage />}></Route>
          <Route exact path="/öppettider" element={<OpeningPage />}></Route>
          <Route exact path="/karta" element={<KartaPage />}></Route>
          <Route exact path="/projektsida" element={<Projekt />}></Route>
          <Route exact path="/kottlador" element={<ShopPage />}></Route>
          <Route exact path="/butik" element={<ShopPage2 />}></Route>
          <Route path="/butik/:id" element={<ProductPage />} />
        </Routes>
      </div>
    </HashRouter>
  );
}

export default App;
