import React from 'react';

import InstagramFeed  from 'react-ig-feed'
import 'react-ig-feed/dist/index.css'

const Instagram = ({ token }) => {
    return (
        <>
        <a href="https://www.instagram.com/forsmans_pa_aspoja/" target="_blank" rel="noreferrer">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: '#000000', marginBottom: '15px' }}>
                <i className="fa fa-instagram" aria-hidden="true" style={{ marginLeft: '4px' }}></i>
                <h2 style={{ margin: 0 }}>Följ oss på Instagram</h2>
            </div>
        </a>
        {token && <InstagramFeed token={token} counter="6"/>}
        </>

    )
}

export default Instagram;